<template>
  <div class="container-fluid">
    <h3>{{ msg }}</h3>
    <div
      v-if="showLoader"
      class="spinner-border"
      role="status"
      style="margin-top: 3vh"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div style="padding: 20px" v-if="!showLoader">
      <div style="margin-top: 1vh; margin-bottom: 1vh">
        <input
          type="text"
          class="form-control"
          id="formGroupExampleInput"
          placeholder="Search by anything"
          v-model="search"
        />
      </div>
      <div style="max-height: 60vh; overflow-y: auto">
        <table class="table table-hover custom-table">
          <thead class="sticky-header">
            <tr class="text-start" style="color: #272e69">
              <th>Company</th>
              <th>Summary</th>
              <th>Reporter</th>
              <th>Status</th>
              <th>Created</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="position-relative text-start"
              v-for="ticket in filteredTickets"
              :key="ticket.id"
            >
              <td style="vertical-align: middle">
                <span :class="getBadgeClass(ticket.company)" class="badge">
                  {{ ticket.company }}
                </span>
              </td>
              <td style="vertical-align: middle">{{ ticket.summary }}</td>
              <td style="vertical-align: middle">{{ ticket.reportor }}</td>
              <td style="vertical-align: middle">
                <span
                  :class="getBadgeClassByStatus(ticket.status)"
                  class="badge"
                >
                  {{ ticket.status }}
                </span>
              </td>
              <td style="vertical-align: middle">
                {{ formatTimestamp(ticket.created) }}
              </td>
              <td style="vertical-align: middle">
                {{ ticket.id }}
              </td>
              <a :href="ticket.link" target="_blank"></a>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-center" style="margin-top: 2vh">
        <div class="col-6" align="right">
          <p>{{ filteredTickets.length }}</p>
        </div>
        <div class="col-6" align="left">
          <i class="bi bi-list-ul"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "./../config/index";
export default {
  name: "PortalComponent",
  beforeMount() {
    if (!("Notification" in window)) {
      alert("This browser does not support notifications.");
    } else {
      Notification.requestPermission().then(() => {});
    }
  },
  mounted() {
    // Initial fetch
    this.gettickets()
      .then(() => {
        return Notification.requestPermission();
      })
      .then((permission) => {
        this.polling = setInterval(this.gettickets, 60000); // Poll every minute
        if (permission === "granted") {
          this.areNotificationsEnabled = setInterval(
            this.checkNewWaitingForSupportTickets,
            120000
          );
        }
      });
  },
  beforeUnmount() {
    if (this.polling) clearInterval(this.polling); // Clear polling on component destruction
    if (this.areNotificationsEnabled)
      clearInterval(this.areNotificationsEnabled); // Clear polling on component destruction
  },
  data: () => ({
    requestsent: false,
    message: "Request successfully sent",
    request: {
      name: "",
      email: "",
      system: "",
      problem: "",
    },
    ticketpayload: [],
    showLoader: true,
    search: "",
    polling: null,
    areNotificationsEnabled: null,
  }),
  props: {
    msg: String,
  },
  computed: {
    filteredTickets() {
      let result = this.ticketpayload;
      // First, filter based on the search term if there is one
      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        result = result.filter((ticket) => {
          const company = ticket.company ? ticket.company.toLowerCase() : "";
          const status = ticket.status ? ticket.status.toLowerCase() : "";
          const summary = ticket.summary ? ticket.summary.toLowerCase() : "";
          const reporter = ticket.reportor ? ticket.reportor.toLowerCase() : "";
          const created = ticket.created ? ticket.created.toLowerCase() : "";
          const id = ticket.id ? ticket.id.toLowerCase() : "";
          return (
            company.includes(searchTerm) ||
            status.includes(searchTerm) ||
            summary.includes(searchTerm) ||
            reporter.includes(searchTerm) ||
            created.includes(searchTerm) ||
            id.includes(searchTerm)
          );
        });
      }
      return result;
    },
  },
  methods: {
    async gettickets() {
      this.showLoader = true;
      axios.defaults.headers.common["key"] = config.headerkey;
      const gettickets = await axios.get(config.servicegetticketsendpoint);
      this.ticketpayload = gettickets.data;
      this.showLoader = false;
    },
    checkNewWaitingForSupportTickets() {
      const newTicketsWaitingForSupport = this.ticketpayload.filter(
        (ticket) => ticket.status === "Waiting for support"
      ).length;
      if (newTicketsWaitingForSupport > 0) {
        this.createNotification({
          title: "Tickets waiting for support",
          body:
            newTicketsWaitingForSupport === 1
              ? `There is ${newTicketsWaitingForSupport} ticket waiting for your attention!`
              : `There are ${newTicketsWaitingForSupport} tickets waiting for your attention!`,
          isRequireInteraction: true,
          customAudioUrl: require("@/assets/notification-sound-zelda.mp3"),
        });
      }
    },
    createNotification({
      title,
      body,
      iconUrl = null,
      imageUrl = null,
      isRequireInteraction = false,
      customAudioUrl = null,
    }) {
      const options = {
        body,
        icon: iconUrl,
        image: imageUrl,
        silent: !!customAudioUrl,
        requireInteraction: isRequireInteraction,
      };
      new Notification(title, options);
      if (customAudioUrl) {
        new Audio(customAudioUrl).play();
      }
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    getBadgeClass(companyName) {
      switch (companyName) {
        case "Abstrax":
          return "badge-abstrax";
        case "BigRentz":
          return "badge-bigrentz";
        case "WiseGuyz":
          return "badge-wiseguyz";
        default:
          return ""; // Default class or return null
      }
    },
    getBadgeClassByStatus(status) {
      switch (status) {
        case "Waiting for customer":
          return "badge-waiting";
        case "In Progress":
          return "badge-in-progress";
        case "Waiting for support":
          return "badge-waiting-for-support";
        case "Waiting for 3rd Party":
          return "badge-waiting-for-3rd-party";
        default:
          return ""; // Default class or return null
      }
    },
  },
};
</script>

<style scoped>
.custom-table {
  border-collapse: collapse;
  border-spacing: 0;
}
.custom-table tr {
  cursor: pointer;
}
.custom-table tr > a {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent;
}
.custom-table td {
  border: none;
  padding: 8px;
  background-color: #f3f3f3;
  color: #1c1c1c;
  border-radius: 0 0 0 0;
}
.custom-table th {
  border: none;
  padding: 8px;
  background-color: #f3f3f3;
  color: #1c1c1c;
  border-radius: 5px 5px 0 0;
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  /* Change this to your desired background color */
  z-index: 1;
  /* Ensure it appears above the table content */
}
.sticky-header th {
  font-weight: bold;
  /* Add any other styles as needed */
}
.btn-group-style {
  color: #f6f6f6;
  background: #272e69;
  border: 1px solid #ffffff;
  border-radius: 5px;
}
.badge-abstrax {
  background-color: rgb(255, 212, 103);
  color: black;
  /* Replace with your color for Abstrax */
}
.badge-bigrentz {
  background-color: orange;
  color: black;
  /* Replace with your color for Bigrentz */
}
.badge-wiseguyz {
  background-color: #b0fc43;
  color: black;
}
.badge-waiting {
  background-color: #9fffa3;
  color: black;
}
.badge-waiting-for-support {
  background-color: #ff9c85;
  color: black;
}
.badge-in-progress {
  background-color: #93a5ff;
  color: black;
}
.badge-waiting-for-3rd-party {
  background-color: #8ac58c;
  color: black;
}
</style>
