<template>
  <img alt="wg" src="@/assets/wg-logo.png" style="max-height: 100px; max-width: 800px" />
  <Portal v-if="showPortal" msg="Support Ticket Dashboard" />
  <div class="key-page" v-if="!showPortal" style="margin-top: 1vh">
    <h3>Enter Your Access Key</h3>
    <form @submit.prevent="validateKey">
      <div class="container-fluid text-center">
        <div class="row">
          <div class="col"></div>
          <div class="col-lg-4 col-md-8 col-sm-10 col-xs-12 justify-content-center" style="margin-top: 3vh">
            <div class="input-group mb-3">
              <input
                v-model="enteredKey"
                type="text"
                class="form-control"
                placeholder="enter your access key"
                aria-label="accesskey"
                aria-describedby="button-addon2"
              />
              <button class="btn btn-outline-secondary" type="submit" id="button-addon2" @click="checkkey()"><i class="bi bi-key"></i></button>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Portal from "./components/Portal.vue";
import config from "./config/index";

export default {
  name: "App",
  data: () => ({
    enteredKey: "",
    showPortal: false,
  }),
  components: {
    Portal,
  },
  methods: {
    async checkkey() {
      if (this.enteredKey == config.key) {
        this.showPortal = true;
      }
    },
  },
  async created() {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
