import { createRouter, createWebHistory } from 'vue-router';

// Import components for your routes
import Portal from './../components/Portal';



const routes = [
  {
    path: '/',
    name: 'Portal',
    component: Portal,
  },
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(), // Using createWebHistory instead of mode: 'history'
  routes
});

export default router;

